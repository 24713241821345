import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  {
    path: '/pertandingan',
    name: 'pertandingan',
    component: () => import(/* webpackChunkName: "pertandingan" */ '../views/Pertandingan.vue')
  },
  {
    path: '/penilaian/tunggal',
    name: 'penilaian-tunggal',
    component: () => import(/* webpackChunkName: "penilaian/tunggal" */ '../views/penilaian/Tunggal.vue')
  },
  {
    path: '/penilaian/ganda',
    name: 'penilaian-ganda',
    component: () => import(/* webpackChunkName: "penilaian/ganda" */ '../views/penilaian/Ganda.vue')
  },
  {
    path: '/penilaian/regu',
    name: 'penilaian-regu',
    component: () => import(/* webpackChunkName: "penilaian/regu" */ '../views/penilaian/Regu.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
